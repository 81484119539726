@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

$font : Oswald;
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $font;
    text-decoration: none;
    color: black;
}
body {
    scrollbar-width: none;
}
@media screen and (max-width: 1100px) {
    %link {
        text-decoration: None;
    }
    %sort-button {
        margin: 0 0 0 4px;
        height: 10px;
        width: 10px;
        border: none;
        all: outset;
        background-size: cover;
    }
    .root-container {
        max-width: 100%;
        max-height: 100%;
    }
    .hamburger-bar {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        height: 55px;
        .hamburger {
            height: 40px;
            width: 40px;
            margin: 9px 13px 0 0;
            background-image: url('icons/hamburger_menu.jpg');
            background-size: contain;
            border: None;
        }
        .open {
            transform: rotate(90deg);
            transition: 0.2s ease-out;
        }
        .close {
            transform: rotate(0);
            transition: 0.2s ease-out;
        }
    }
    .header {
        height: 79px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0;
        margin-bottom: 10px;
        .home {
            @extend %link;
            h3 {
                width: 100%;
                height: 60px;
                font-size: 42px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 1.32px;
                text-align: center;
            }
        }
    }
    .menu {
        position: absolute;
        top: 50px;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        padding: 10px 10px;
        display: flex;
        flex-direction: column;
        font-size: 32px;
        font-weight: 300;
        line-height: 135.1%; /* 43.232px */
        letter-spacing: 1.92px;
        .language {
            display: flex;
            flex-direction: row;
            padding-top: 14px;
            button {
                width: 36px;
                height: 36px;
                border: None;
                font-size: 32px;
                font-weight: 300;
                text-align: left;
                transition: none;
                background-color: white;
                all: outset;
            }
            p {
                padding-right: 6px;
            }
            .active-language {
                cursor: default;
                text-decoration: underline;
            }
            .inactive-language {
                cursor: pointer;
            }
        }
    }
    .hidden {
        transform: translateX(-100%);
        transition: 0.2s ease-out;
    }
    .show {
        transform: translateX(0);
        transition: 0.2s ease-out;
    }
    .work-view {
        max-width: 100%;
        max-height: calc(100vh - 150px);
        padding: 0px 5px;
        margin-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: baseline;
        .oeuvre {
            padding: 0 5px;
            max-width: 100%;
            max-height: calc(calc(100vh - 150px));
        }
        .description {
            align-self: flex-end;
            padding: 3px 5px;
            h3 {
                font-weight: 400;
                letter-spacing: 0.96px;
            }
            p {
                letter-spacing: 0.96px;
                font-weight: 300;
            }
        }
    }
    .container {
        padding: 0 10px;
        .works-menu-container {
            margin-bottom: 12px;
            a {
                padding: 0 46px 0 2px;
                font-size: 16px;
                font-weight: 200;
                line-height: normal;
                letter-spacing: 0.96px;
            }
            .active {
                text-decoration: underline;
            }
        }
        .works-list {
            thead {
                th {
                    padding-bottom: 6px;
                    width: 300px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                }
            }
            tbody {
                tr {
                    td {
                        padding-bottom: 1px;
                        font-weight: 300;
                        font-size: 16px;
                        letter-spacing: 0.48px;
                        padding-right: 6px;
                    }
                }
            }
            .up {
                @extend %sort-button;
                background-image: url('icons/tri_icone_up.jpg');
            }
            .down {
                @extend %sort-button;
                background-image: url('icons/tri_icone_down.jpg');
            }
        }
        .table-container {
            overflow-x: scroll;
            scrollbar-width: none;
        }
        .table-container::-webkit-scrollbar {
            display: none;
        }
        .exhibitions-menu-container {
            height: 24px;
            margin-bottom: 10px;
            white-space: nowrap;
            overflow-x: scroll;
            scrollbar-width: none;
            a {
                padding: 0 30px 0 2px;
                font-size: 16px;
                font-weight: 200;
                line-height: normal;
                letter-spacing: 0.96px;
            }
            .active {
                text-decoration: underline;
            }
        }
        .exhibitions-menu-container::-webkit-scrollbar { 
            display: none; 
        }
    }
    .contact-container, .biography-container {
        padding: 0 10px;
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.48px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .content {
            padding-top: 30px;
            width: 100%;
        }
        .biography-content {
            padding-top: 20px;
        }
        .biography-author {
            width: 100%;
            padding-top: 2px;
            padding-bottom: 150px;
        }
        .autoportrait {
            padding-top: 20px;
            width: 100%;
            max-width: 500px;
        }
        form {
            input {
                width: 230px;
                height: 30px;
                margin-right: 4px;
            }
            button {
                height: 30px;
                width: 73px;
                border: 1px solid black;
                background-color: white;
                font-size: 16px;
                letter-spacing: 0.48px;
                box-shadow: 1px 1px 1px #848484;
            }
        }
        h2 {
            color: #B80B0B;
            font-size: 16px;
            font-weight: 200;
            letter-spacing: 0.84px;
        }
    }
}
@media screen and (min-width: 1101px) {
    $menu-size: 400px;
    $header-size: 180px;
    %pc-link {
        text-decoration: None;
    }
    %pc-link-active {
        color: #848484;
    }
    %pc-sort-button {
        margin: 0 0 0 4px;
        height: 10px;
        width: 10px;
        border: none;
        all: outset;
        background-size: cover;
    }
    .root-container {
        max-width: 100%;
        max-height: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .hamburger-bar {
        display: none;
    }
    .header {
        height: 130px;
        width: 100vw;
        padding: 20px 0 10px 0;
        display: flex;
        .home {
            @extend %pc-link;
            height: 100px;
            padding: 25px 0 0 100px;
            h3 {
                width: 100%;
                height: 100%;
                font-size: 40px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 3.2px;
                text-align: center;
            }
            h3:hover {
                @extend %pc-link-active;
            }
        }
    }
    .menu {
        width: $menu-size;
        height: calc(100vh - 180px);
        background-color: rgb(255, 255, 255);
        padding: 100px 10px 10px 100px;
        display: flex;
        flex-direction: column;
        font-size: 32px;
        font-weight: 300;
        line-height: 135.1%; /* 43.232px */
        letter-spacing: 1.92px;
        a:hover {
            @extend %pc-link-active
        }
        .active {
            @extend %pc-link-active
        }
        .language {
            display: flex;
            flex-direction: row;
            padding-top: 14px;
            button {
                width: 36px;
                height: 36px;
                border: None;
                font-size: 32px;
                font-weight: 300;
                text-align: left;
                transition: none;
                background-color: white;
                all: outset;
            }
            p {
                padding-right: 6px;
            }
            .active-language {
                cursor: default;
                text-decoration: underline;
            }
            .inactive-language {
                cursor: pointer;
            }
        }
    }
    .work-view {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: baseline;
        max-width: calc(100vw - $menu-size - 100px);
        max-height: calc(100vh - 180px);
        padding: 0px 10px;
        margin-bottom: 10px;
        .oeuvre {
            max-width: calc(100vw - $menu-size - 100px);
            max-height: 100%;
            padding-right: 10px;
        }
        .description {
            align-self: flex-end;
            min-width: 100px;
            h3 {
                font-weight: 400;
                letter-spacing: 0.96px;
            }
            p {
                letter-spacing: 0.96px;
                font-weight: 300;
            }
        }
    }
    .container {
        width: calc(98vw - $menu-size);
        max-width: 1300px;
        height: calc(100vh - 130px);
        overflow-y: scroll;
        scrollbar-width: none;
        .works-menu-container {
            height: 24px;
            margin-bottom: 12px;
            a {
                padding: 0 46px 0 2px;
                font-size: 16px;
                font-weight: 200;
                line-height: normal;
                letter-spacing: 0.96px;
            }
            a:hover {
                @extend %pc-link-active;
            }
            .active {
                text-decoration: underline;
                cursor: text;
            }
            .active:hover {
                color:black;
            }
        }
        .works-list {
            thead {
                th {
                    padding-bottom: 6px;
                    width: 300px;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                }
                th:hover {
                    @extend %pc-link-active;
                    cursor: pointer;
                }
            }
            tbody {
                overflow-y: scroll;
                
                tr {
                    td {
                        padding-bottom: 1px;
                        font-weight: 300;
                        font-size: 16px;
                        letter-spacing: 0.48px;
                        padding-right: 6px;
                    }
                    td a:hover {
                        @extend %pc-link-active;
                    }
                }
            }
            .up {
                @extend %pc-sort-button;
                background-image: url('icons/tri_icone_up.jpg');
            }
            .down {
                @extend %pc-sort-button;
                background-image: url('icons/tri_icone_down.jpg');
            }
        }
        .table-container {
            overflow-x: scroll;
            scrollbar-width: none;
        }
        .table-container::-webkit-scrollbar {
            display: none;
        }
        .exhibitions-menu-container {
            height: 24px;
            margin-bottom: 10px;
            white-space: nowrap;
            a {
                padding: 0 30px 0 2px;
                font-size: 16px;
                font-weight: 200;
                line-height: normal;
                letter-spacing: 0.96px;
            }
            a:hover {
                @extend %pc-link-active;
            }
            .active {
                text-decoration: underline;
                cursor:text;
            }
            .active:hover {
                color:black;
            }
        }
        .exhibitions-menu-container::-webkit-scrollbar { 
            display: none; 
        }
    }
    .container::-webkit-scrollbar {
        display: none;
    }
    .contact-container, .biography-container {
        max-width: calc(90vw - $menu-size);
        max-height: calc(100vh - 130px);
        font-size: 16px;
        font-weight: 300;
        letter-spacing: 0.48px;
        overflow-y: scroll;
        .content {
            padding-top: 50px;
        }
        .biography-content {
            margin-top: 20px;
        }
        .biography-author {
            margin-top: 2px;
            margin-bottom: 250px;
        }
        form {
            input {
                width: 230px;
                height: 30px;
                margin-right: 4px;
            }
            button {
                height: 30px;
                width: 73px;
                border: 1px solid black;
                background-color: white;
                font-size: 16px;
                letter-spacing: 0.48px;
                box-shadow: 1px 1px 1px #848484;
            }
            button:hover {
                cursor: pointer;
                background-color: #84848415;
                box-shadow: 0 0 0 #848484;
            }
        }
        h2 {
            color: #B80B0B;
            font-size: 16px;
            font-weight: 200;
            letter-spacing: 0.84px;
        }
        .autoportrait {
            margin-top: 20px;
            max-width: 400px;
            margin-left: calc(50% - 200px);
        }
    } 
    .contact-container {
        scrollbar-width: none;
    }
    .contact-container::-webkit-scrollbar {
        display: none;
    }
}