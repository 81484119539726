@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Oswald;
  text-decoration: none;
  color: black;
}

body {
  scrollbar-width: none;
}

@media screen and (max-width: 1100px) {
  .header .home {
    text-decoration: None;
  }
  .container .works-list .down, .container .works-list .up {
    margin: 0 0 0 4px;
    height: 10px;
    width: 10px;
    border: none;
    all: outset;
    background-size: cover;
  }
  .root-container {
    max-width: 100%;
    max-height: 100%;
  }
  .hamburger-bar {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    height: 55px;
  }
  .hamburger-bar .hamburger {
    height: 40px;
    width: 40px;
    margin: 9px 13px 0 0;
    background-image: url("icons/hamburger_menu.jpg");
    background-size: contain;
    border: None;
  }
  .hamburger-bar .open {
    transform: rotate(90deg);
    transition: 0.2s ease-out;
  }
  .hamburger-bar .close {
    transform: rotate(0);
    transition: 0.2s ease-out;
  }
  .header {
    height: 79px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
    margin-bottom: 10px;
  }
  .header .home h3 {
    width: 100%;
    height: 60px;
    font-size: 42px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.32px;
    text-align: center;
  }
  .menu {
    position: absolute;
    top: 50px;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 300;
    line-height: 135.1%; /* 43.232px */
    letter-spacing: 1.92px;
  }
  .menu .language {
    display: flex;
    flex-direction: row;
    padding-top: 14px;
  }
  .menu .language button {
    width: 36px;
    height: 36px;
    border: None;
    font-size: 32px;
    font-weight: 300;
    text-align: left;
    transition: none;
    background-color: white;
    all: outset;
  }
  .menu .language p {
    padding-right: 6px;
  }
  .menu .language .active-language {
    cursor: default;
    text-decoration: underline;
  }
  .menu .language .inactive-language {
    cursor: pointer;
  }
  .hidden {
    transform: translateX(-100%);
    transition: 0.2s ease-out;
  }
  .show {
    transform: translateX(0);
    transition: 0.2s ease-out;
  }
  .work-view {
    max-width: 100%;
    max-height: calc(100vh - 150px);
    padding: 0px 5px;
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
  }
  .work-view .oeuvre {
    padding: 0 5px;
    max-width: 100%;
    max-height: calc(100vh - 150px);
  }
  .work-view .description {
    align-self: flex-end;
    padding: 3px 5px;
  }
  .work-view .description h3 {
    font-weight: 400;
    letter-spacing: 0.96px;
  }
  .work-view .description p {
    letter-spacing: 0.96px;
    font-weight: 300;
  }
  .container {
    padding: 0 10px;
  }
  .container .works-menu-container {
    margin-bottom: 12px;
  }
  .container .works-menu-container a {
    padding: 0 46px 0 2px;
    font-size: 16px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0.96px;
  }
  .container .works-menu-container .active {
    text-decoration: underline;
  }
  .container .works-list thead th {
    padding-bottom: 6px;
    width: 300px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
  .container .works-list tbody tr td {
    padding-bottom: 1px;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.48px;
    padding-right: 6px;
  }
  .container .works-list .up {
    background-image: url("icons/tri_icone_up.jpg");
  }
  .container .works-list .down {
    background-image: url("icons/tri_icone_down.jpg");
  }
  .container .table-container {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .container .table-container::-webkit-scrollbar {
    display: none;
  }
  .container .exhibitions-menu-container {
    height: 24px;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .container .exhibitions-menu-container a {
    padding: 0 30px 0 2px;
    font-size: 16px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0.96px;
  }
  .container .exhibitions-menu-container .active {
    text-decoration: underline;
  }
  .container .exhibitions-menu-container::-webkit-scrollbar {
    display: none;
  }
  .contact-container, .biography-container {
    padding: 0 10px;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.48px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contact-container .content, .biography-container .content {
    padding-top: 30px;
    width: 100%;
  }
  .contact-container .biography-content, .biography-container .biography-content {
    padding-top: 20px;
  }
  .contact-container .biography-author, .biography-container .biography-author {
    width: 100%;
    padding-top: 2px;
    padding-bottom: 150px;
  }
  .contact-container .autoportrait, .biography-container .autoportrait {
    padding-top: 20px;
    width: 100%;
    max-width: 500px;
  }
  .contact-container form input, .biography-container form input {
    width: 230px;
    height: 30px;
    margin-right: 4px;
  }
  .contact-container form button, .biography-container form button {
    height: 30px;
    width: 73px;
    border: 1px solid black;
    background-color: white;
    font-size: 16px;
    letter-spacing: 0.48px;
    box-shadow: 1px 1px 1px #848484;
  }
  .contact-container h2, .biography-container h2 {
    color: #B80B0B;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 0.84px;
  }
}
@media screen and (min-width: 1101px) {
  .header .home {
    text-decoration: None;
  }
  .container .exhibitions-menu-container a:hover, .container .works-list tbody tr td a:hover, .container .works-list thead th:hover, .container .works-menu-container a:hover, .menu .active, .menu a:hover, .header .home h3:hover {
    color: #848484;
  }
  .container .works-list .down, .container .works-list .up {
    margin: 0 0 0 4px;
    height: 10px;
    width: 10px;
    border: none;
    all: outset;
    background-size: cover;
  }
  .root-container {
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .hamburger-bar {
    display: none;
  }
  .header {
    height: 130px;
    width: 100vw;
    padding: 20px 0 10px 0;
    display: flex;
  }
  .header .home {
    height: 100px;
    padding: 25px 0 0 100px;
  }
  .header .home h3 {
    width: 100%;
    height: 100%;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 3.2px;
    text-align: center;
  }
  .menu {
    width: 400px;
    height: calc(100vh - 180px);
    background-color: rgb(255, 255, 255);
    padding: 100px 10px 10px 100px;
    display: flex;
    flex-direction: column;
    font-size: 32px;
    font-weight: 300;
    line-height: 135.1%; /* 43.232px */
    letter-spacing: 1.92px;
  }
  .menu .language {
    display: flex;
    flex-direction: row;
    padding-top: 14px;
  }
  .menu .language button {
    width: 36px;
    height: 36px;
    border: None;
    font-size: 32px;
    font-weight: 300;
    text-align: left;
    transition: none;
    background-color: white;
    all: outset;
  }
  .menu .language p {
    padding-right: 6px;
  }
  .menu .language .active-language {
    cursor: default;
    text-decoration: underline;
  }
  .menu .language .inactive-language {
    cursor: pointer;
  }
  .work-view {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: baseline;
    max-width: calc(100vw - 400px - 100px);
    max-height: calc(100vh - 180px);
    padding: 0px 10px;
    margin-bottom: 10px;
  }
  .work-view .oeuvre {
    max-width: calc(100vw - 400px - 100px);
    max-height: 100%;
    padding-right: 10px;
  }
  .work-view .description {
    align-self: flex-end;
    min-width: 100px;
  }
  .work-view .description h3 {
    font-weight: 400;
    letter-spacing: 0.96px;
  }
  .work-view .description p {
    letter-spacing: 0.96px;
    font-weight: 300;
  }
  .container {
    width: calc(98vw - 400px);
    max-width: 1300px;
    height: calc(100vh - 130px);
    overflow-y: scroll;
    scrollbar-width: none;
  }
  .container .works-menu-container {
    height: 24px;
    margin-bottom: 12px;
  }
  .container .works-menu-container a {
    padding: 0 46px 0 2px;
    font-size: 16px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0.96px;
  }
  .container .works-menu-container .active {
    text-decoration: underline;
    cursor: text;
  }
  .container .works-menu-container .active:hover {
    color: black;
  }
  .container .works-list thead th {
    padding-bottom: 6px;
    width: 300px;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
  }
  .container .works-list thead th:hover {
    cursor: pointer;
  }
  .container .works-list tbody {
    overflow-y: scroll;
  }
  .container .works-list tbody tr td {
    padding-bottom: 1px;
    font-weight: 300;
    font-size: 16px;
    letter-spacing: 0.48px;
    padding-right: 6px;
  }
  .container .works-list .up {
    background-image: url("icons/tri_icone_up.jpg");
  }
  .container .works-list .down {
    background-image: url("icons/tri_icone_down.jpg");
  }
  .container .table-container {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  .container .table-container::-webkit-scrollbar {
    display: none;
  }
  .container .exhibitions-menu-container {
    height: 24px;
    margin-bottom: 10px;
    white-space: nowrap;
  }
  .container .exhibitions-menu-container a {
    padding: 0 30px 0 2px;
    font-size: 16px;
    font-weight: 200;
    line-height: normal;
    letter-spacing: 0.96px;
  }
  .container .exhibitions-menu-container .active {
    text-decoration: underline;
    cursor: text;
  }
  .container .exhibitions-menu-container .active:hover {
    color: black;
  }
  .container .exhibitions-menu-container::-webkit-scrollbar {
    display: none;
  }
  .container::-webkit-scrollbar {
    display: none;
  }
  .contact-container, .biography-container {
    max-width: calc(90vw - 400px);
    max-height: calc(100vh - 130px);
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.48px;
    overflow-y: scroll;
  }
  .contact-container .content, .biography-container .content {
    padding-top: 50px;
  }
  .contact-container .biography-content, .biography-container .biography-content {
    margin-top: 20px;
  }
  .contact-container .biography-author, .biography-container .biography-author {
    margin-top: 2px;
    margin-bottom: 250px;
  }
  .contact-container form input, .biography-container form input {
    width: 230px;
    height: 30px;
    margin-right: 4px;
  }
  .contact-container form button, .biography-container form button {
    height: 30px;
    width: 73px;
    border: 1px solid black;
    background-color: white;
    font-size: 16px;
    letter-spacing: 0.48px;
    box-shadow: 1px 1px 1px #848484;
  }
  .contact-container form button:hover, .biography-container form button:hover {
    cursor: pointer;
    background-color: rgba(132, 132, 132, 0.0823529412);
    box-shadow: 0 0 0 #848484;
  }
  .contact-container h2, .biography-container h2 {
    color: #B80B0B;
    font-size: 16px;
    font-weight: 200;
    letter-spacing: 0.84px;
  }
  .contact-container .autoportrait, .biography-container .autoportrait {
    margin-top: 20px;
    max-width: 400px;
    margin-left: calc(50% - 200px);
  }
  .contact-container {
    scrollbar-width: none;
  }
  .contact-container::-webkit-scrollbar {
    display: none;
  }
}

